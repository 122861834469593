import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton, Tooltip } from '@mui/material';

interface Option {
    onClick: () => void;
    label: string;
    icon?: React.ReactNode;
    color?: 'primary' | 'secondary' | 'error' | 'info';
}
interface DropDownMenuProps {
    title: React.ReactNode;
    options: Option[];
    startIcon?: React.ReactNode;
    idName: string;
}
const DropDownMenu = ({ title, options, startIcon, idName }: DropDownMenuProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Tooltip title='Log In as User'>
                {title ? (
                    <Button
                        id={`${idName}-button`}
                        aria-controls={open ? `${idName}-menu` : undefined}
                        aria-haspopup='true'
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        startIcon={startIcon}
                    >
                        {title}
                    </Button>
                ) : (
                    <IconButton onClick={handleClick}>{startIcon}</IconButton>
                )}
            </Tooltip>
            <Menu
                id={`${idName}-menu`}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': `${idName}-button`
                }}
            >
                {options.map(option => (
                    <MenuItem key={option.label} onClick={option.onClick}>
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default DropDownMenu;
