import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { AccountCircle, Mail, Notifications, People } from '@mui/icons-material';
import { Link } from 'react-router-dom';

export const mainListItems = (
    <React.Fragment>
        <ListItemButton component={Link} to='/user/profile'>
            <ListItemIcon>
                <AccountCircle />
            </ListItemIcon>
            <ListItemText primary='Profile' />
        </ListItemButton>
        {/* <ListItemButton>
            <ListItemIcon>
                <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary='Users' />
        </ListItemButton> */}
    </React.Fragment>
);

export const adminItems = (
    <React.Fragment>
        <ListSubheader component='div' inset>
            Admin
        </ListSubheader>
        {/* <ListItemButton component={Link} to='/admin/metrics'>
            <ListItemIcon>
                <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary='Metrics' />
        </ListItemButton> */}
        {/* <ListItemButton>
            <ListItemIcon>
                <LayersIcon />
            </ListItemIcon>
            <ListItemText primary='Integrations' />
        </ListItemButton> */}
        <ListItemButton component={Link} to='/admin/users'>
            <ListItemIcon>
                <People />
            </ListItemIcon>
            <ListItemText primary='Users' />
        </ListItemButton>
        <ListItemButton component={Link} to='/admin/subscriptions'>
            <ListItemIcon>
                <Mail />
            </ListItemIcon>
            <ListItemText primary='Subscriptions' />
        </ListItemButton>
    </React.Fragment>
);
export const sysAdminItems = (
    <React.Fragment>
        <ListItemButton component={Link} to='/admin/notifications'>
            <ListItemIcon>
                <Notifications />
            </ListItemIcon>
            <ListItemText primary='Notifications' />
        </ListItemButton>
    </React.Fragment>
);
