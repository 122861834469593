import { Navigate } from 'react-router-dom';
import { useUser } from '../contexts/user';
import { RequireAuth } from 'react-auth-kit';
import LoadingOverlay from './LoadingOverlay';

export interface PermissionsProps {
    requireRoles?: ('wineryAdmin' | 'sysAdmin')[];
    children: React.ReactNode;
    redirect?: boolean;
}

const Permissions = ({ requireRoles, children, redirect }: PermissionsProps) => {
    const { user, loading } = useUser();

    if (loading) {
        return <LoadingOverlay />;
    }

    let hasRequiredRole = true;

    if (requireRoles?.includes('wineryAdmin') && !user.isAtLeastWineryAdmin) {
        hasRequiredRole = false;
    }

    if (requireRoles?.includes('sysAdmin') && !user.Sys_Admin) {
        hasRequiredRole = false;
    }

    if (!hasRequiredRole) {
        if (redirect) {
            return <Navigate to='/user/profile' />;
        }
        return null;
    }

    if (redirect) {
        return (
            <RequireAuth loginPath='/auth/login'>
                <>{children}</>
            </RequireAuth>
        );
    }

    return <>{children}</>;
};

export default Permissions;
