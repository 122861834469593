import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Checkbox, IconButton, ListItemText } from '@mui/material';
import { useUser } from '../../contexts/user';
import { useLocation, useNavigate } from 'react-router-dom';
import { Close } from '@mui/icons-material';

const ITEM_HEIGHT = 36;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

const WinerySelect = () => {
    const { user: loggedInUser } = useUser();
    const { Wineries } = loggedInUser;
    const location = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);
    const wineries = params.get('wineries');
    const selectedWineries = wineries ? wineries.split(',') : [];

    const availableWineries = (Wineries || []).map(winery => winery.Winery_Name || '');

    const setWineries = (wineries: string) => {
        const queryParams = new URLSearchParams(location.search);
        if (wineries.length === 0) {
            queryParams.delete('wineries');
        } else {
            queryParams.set('wineries', wineries);
        }

        navigate(`?${queryParams.toString()}`);
    };

    const handleChange = (event: SelectChangeEvent<typeof selectedWineries>) => {
        const {
            target: { value }
        } = event;

        const valueArray = typeof value === 'string' ? value.split(',') : value;
        setWineries(valueArray.join(',') || '');
    };

    return (
        <div>
            <div>
                <FormControl sx={{ width: '100%' }}>
                    <InputLabel size='small' id='wineries-select-label'>
                        Search in Wineries
                    </InputLabel>
                    <Select
                        size='small'
                        sx={{ textAlign: 'left' }}
                        labelId='wineries-select-label'
                        id='wineries-select'
                        multiple
                        value={selectedWineries}
                        onChange={handleChange}
                        input={<OutlinedInput label='Search in Wineries' />}
                        renderValue={selected => selected.join(', ')}
                        MenuProps={MenuProps}
                        IconComponent={
                            wineries
                                ? () => (
                                      <IconButton
                                          aria-label='clear all selected wineries'
                                          onClick={() => setWineries('')}
                                      >
                                          <Close fontSize='small' />
                                      </IconButton>
                                  )
                                : undefined
                        }
                    >
                        {availableWineries.map(winery => (
                            <MenuItem key={winery} value={winery}>
                                <Checkbox checked={selectedWineries.indexOf(winery) > -1} />
                                <ListItemText primary={winery} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </div>
    );
};

export default WinerySelect;
