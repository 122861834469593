/* eslint-disable import/no-anonymous-default-export */
import * as React from 'react';
import {
    ListItem,
    ListItemAvatar,
    Avatar,
    Box,
    Grid,
    Toolbar,
    Container,
    Paper,
    ListItemText,
    Typography,
    CircularProgress,
    TablePagination,
    TextField,
    Tooltip
} from '@mui/material';

import { Link, useNavigate, useLocation } from 'react-router-dom';

import useApi from '../../hooks/api';
import MetaTitle from '../../components/MetaTitle';
import { debounce } from '../../util';
import { Emails } from './SubscriptionEdit';
import WinerySelect from '../Users/WinerySelect';

export interface Subscription {
    SubscriptionID: string;
    OwnerID: string;
    Report_OID: string;
    Locale: string;
    InactiveFlags: number;
    ExtensionSettings?: string;
    ModifiedByID: string;
    ModifiedDate: Date;
    Description?: string;
    LastStatus?: string;
    EventType: string;
    MatchData?: string;
    LastRunTime?: Date;
    Parameters?: string;
    DataSettings?: string;
    DeliveryExtension?: string;
    Version: number;
    ReportZone: number;
    ExtensionSettingsParsed?: any;
    Emails?: Emails;
    Winery_Name?: string;
    GroupName?: string;
    Path?: string;
}

export interface Role {
    Role_id: string;
    Role_Name: string;
}

export interface Winery {
    Winery_id: string;
    Winery_Name?: string;
    Last_Sync_Date?: string;
    OpenAI_model?: string;
    POS_Name?: string;
    ChatDTC?: boolean; // whether or not the admin subscription has chosen to add subscription to this platform
    Analytics?: boolean; // whether or not the admin subscription has chosen to add subscription to this platform
    ChatDTC_Access?: boolean; // whether or not the admin subscription can add subscription to this platform
    Analytics_Access?: boolean; // whether or not the admin subscription can add subscription to this platform
}

interface SearchParams {
    search: string;
    wineries: string;
}

const Subscriptions = () => {
    const navigate = useNavigate();
    const { callApi, loading } = useApi();
    const [subscriptions, setSubscriptions] = React.useState<Subscription[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const search = params.get('search') || '';
    const wineries = params.get('wineries') || '';

    const getSubscriptions = (params?: SearchParams) => {
        const { search, wineries } = params || {};
        // initialize empty params object
        const paramsObj: {
            search?: string;
            wineries?: string;
        } = {};

        // conditionally add search params to object
        if (search) {
            paramsObj.search = search;
        }
        if (wineries) {
            paramsObj.wineries = wineries;
        }

        // construct url with search params
        const searchParams = new URLSearchParams(paramsObj);
        const url = new URL('/api/subscriptions', window.location.origin);
        url.search = searchParams.toString();
        const pathWithParams = url.pathname + url.search;

        // fetch subscriptions
        callApi({ url: pathWithParams }).then((data: Subscription[]) => {
            setSubscriptions(data || []);
            setPage(0);
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedGetSubscriptions = React.useCallback(
        debounce(({ search, wineries }: SearchParams) => {
            getSubscriptions({ search, wineries });
        }, 500),
        []
    );

    React.useEffect(() => {
        debouncedGetSubscriptions({
            search: search || '',
            wineries: wineries || ''
        });
    }, [search, wineries, debouncedGetSubscriptions]);

    const setSearch = (query: string) => {
        const queryParams = new URLSearchParams(location.search);
        if (query.length === 0) {
            queryParams.delete('search');
        } else {
            queryParams.set('search', query);
        }

        navigate(`?${queryParams.toString()}`);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to first page when changing rows per page
    };

    const slicedSubscriptions = subscriptions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    return (
        <Box
            component='main'
            sx={{
                backgroundColor: theme =>
                    theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto'
            }}
        >
            <MetaTitle title='Subscriptions' />

            <Toolbar />
            <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                            <Grid container spacing={3} direction='row' pb={2}>
                                <Grid item md={2} xs={12}>
                                    <Typography component='h2' variant='h6' color='primary' gutterBottom>
                                        Subscriptions
                                    </Typography>
                                </Grid>

                                <Grid item md={7} xs={12} justifyContent='center'>
                                    <Tooltip
                                        title='Search anything (email, description, winery name, etc)'
                                        enterDelay={1000}
                                        placement='top'
                                    >
                                        <TextField
                                            size='small'
                                            fullWidth
                                            variant='outlined'
                                            value={search}
                                            onChange={handleSearchChange}
                                            label='Search for a subscription'
                                        />
                                    </Tooltip>
                                </Grid>
                                <Grid item md={3} xs={12} textAlign='right'>
                                    <WinerySelect />
                                </Grid>
                            </Grid>
                            {loading ? (
                                <CircularProgress color='inherit' />
                            ) : (
                                slicedSubscriptions.map(subscription => (
                                    <React.Fragment
                                        key={
                                            subscription.SubscriptionID +
                                            subscription.Description +
                                            subscription.GroupName +
                                            subscription.Path +
                                            subscription.Winery_Name
                                        }
                                    >
                                        <ListItem
                                            button
                                            component={Link}
                                            to={`/admin/subscriptions/${subscription.SubscriptionID}`}
                                        >
                                            <ListItemAvatar>
                                                <Avatar>
                                                    {subscription.Description?.charAt(0) ||
                                                        subscription.Winery_Name?.charAt(0) ||
                                                        ''}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={<>{subscription.Description || '(no description)'} </>}
                                                secondary={subscription.Winery_Name || subscription.GroupName || ''}
                                            />
                                        </ListItem>
                                    </React.Fragment>
                                ))
                            )}
                            <TablePagination
                                component='div'
                                count={subscriptions.length}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Subscriptions;
