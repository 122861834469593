import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from 'react-auth-kit';
import UpdatePassword from './pages/UpdatePassword';
import NotFoundPage from './pages/NotFoundPage';
import Login from './pages/Login';
import { LoadingProvider } from './contexts/loading';
import { StatusProvider } from './contexts/status';
import Status from './components/status';
import NavBar from './components/Layout/NavBar';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { UserProvider } from './contexts/user';
import ResetPassword from './pages/ResetPassword';
import { AlertModalProvider } from './contexts/alertModal';
// import Metrics from './pages/Metrics';
import Notifications from './pages/Notifications';
import { Box } from '@mui/material';
import Profile from './pages/Profile';
import { TitleProvider } from './contexts/title';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from './constants';
import Permissions, { PermissionsProps } from './components/Permissions';

import Users from './pages/Users';
import Subscriptions from './pages/Subscriptions';
import SubscriptionEdit from './pages/Subscriptions/SubscriptionEdit';

const PermissionsWithNavbar = ({ children, ...rest }: PermissionsProps) => {
    return (
        <Permissions {...rest}>
            <>
                <NavBar />
                {children}
            </>
        </Permissions>
    );
};

const App = () => {
    return (
        <div id='App'>
            <Helmet>
                <title>{APP_TITLE}</title>
            </Helmet>
            <TitleProvider>
                <ThemeProvider theme={theme}>
                    <StatusProvider>
                        <LoadingProvider>
                            <AuthProvider
                                authType='cookie'
                                authName='auth'
                                cookieDomain={window.location.hostname}
                                cookieSecure={false}
                            >
                                <UserProvider>
                                    <AlertModalProvider>
                                        <Status />
                                        <Box sx={{ display: 'flex' }}>
                                            <Router>
                                                <Routes>
                                                    {/* redirect / requests to /app */}
                                                    <Route path='/' element={<Navigate to='/admin/users' />} />
                                                    {/* TODO - create metrics page */}
                                                    {/* <Route
                                                        path='/admin/metrics'
                                                        element={
                                                            <PermissionsWithNavbar redirect requireRoles={['wineryAdmin']}>
                                                                <Metrics />
                                                            </PermissionsWithNavbar>
                                                        }
                                                    ></Route> */}
                                                    <Route
                                                        path='/admin/notifications'
                                                        element={
                                                            <PermissionsWithNavbar redirect requireRoles={['sysAdmin']}>
                                                                <Notifications />
                                                            </PermissionsWithNavbar>
                                                        }
                                                    />
                                                    <Route
                                                        path='admin/users/:action?/:SamAccountName?'
                                                        element={
                                                            <PermissionsWithNavbar
                                                                redirect
                                                                requireRoles={['wineryAdmin']}
                                                            >
                                                                <Users />
                                                            </PermissionsWithNavbar>
                                                        }
                                                    />
                                                    <Route
                                                        path='admin/subscriptions/'
                                                        element={
                                                            <PermissionsWithNavbar
                                                                redirect
                                                                requireRoles={['wineryAdmin']}
                                                            >
                                                                <Subscriptions />
                                                            </PermissionsWithNavbar>
                                                        }
                                                    />
                                                    <Route
                                                        path='admin/subscriptions/:id'
                                                        element={
                                                            <PermissionsWithNavbar
                                                                redirect
                                                                requireRoles={['wineryAdmin']}
                                                            >
                                                                <SubscriptionEdit />
                                                            </PermissionsWithNavbar>
                                                        }
                                                    />
                                                    <Route
                                                        path='/user/profile'
                                                        element={
                                                            <PermissionsWithNavbar redirect>
                                                                <Profile />
                                                            </PermissionsWithNavbar>
                                                        }
                                                    />
                                                    <Route
                                                        path='auth/*'
                                                        element={
                                                            <Routes>
                                                                <Route path='login' element={<Login />} />
                                                                <Route
                                                                    path='forgot-password'
                                                                    element={<UpdatePassword />}
                                                                />
                                                                <Route
                                                                    path='welcome/:uuid'
                                                                    element={<ResetPassword resetType='welcome' />}
                                                                />
                                                                <Route
                                                                    path='reset-password/:uuid'
                                                                    element={<ResetPassword resetType='forgot' />}
                                                                />
                                                                <Route path='*' element={<NotFoundPage />} />
                                                            </Routes>
                                                        }
                                                    />
                                                    <Route path='*' element={<NotFoundPage />} />
                                                </Routes>
                                            </Router>
                                        </Box>
                                    </AlertModalProvider>
                                </UserProvider>
                            </AuthProvider>
                        </LoadingProvider>
                    </StatusProvider>
                </ThemeProvider>
            </TitleProvider>
        </div>
    );
};

export default App;
