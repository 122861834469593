import React, { useEffect, useState } from 'react';
import { Stack, Typography, TextField, Button, IconButton } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme';
import Visibility from '@mui/icons-material/VisibilityOutlined';
import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { WINEPULSE_TITLE } from '../constants/api';
import useApi from '../hooks/api';
import { useStatus } from '../contexts/status';
import { useAlertModal } from '../contexts/alertModal';
import { useLoading } from '../contexts/loading';
import { AlertModal } from '../components/wpModal';

interface ResetPasswordProps {
    resetType: 'welcome' | 'forgot';
}

const ResetPassword = ({ resetType }: ResetPasswordProps) => {
    const { setStatus } = useStatus();
    const { callApi } = useApi();
    const { uuid } = useParams();
    const { alertModal } = useAlertModal();
    const navigate = useNavigate();
    const { loading, setLoading } = useLoading();

    const [username, setUsername] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [confirmedPassword, setConfirmedPassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [validToken, setValidToken] = useState(true);

    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const isAdminRequest = urlParams.get('a') === 'true';

    const isWelcome = resetType === 'welcome';

    const checkIsValidToken = async () => {
        setLoading(true);
        const data = await callApi({
            url: `/api/auth/check-reset-token/${uuid}`,
            method: 'POST'
        });
        if (!data || !data.message) {
            setValidToken(false);
        } else if (data.message === 'Password reset token is valid') {
            setValidToken(true);
        } else if (data.message === 'Password reset token is invalid') {
            setValidToken(false);
        }
        setLoading(false);
    };

    useEffect(() => {
        document.title = `Reset password for ${WINEPULSE_TITLE}`;
        checkIsValidToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setUsername(value);
    };

    const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setNewPassword(value);
    };

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setConfirmedPassword(value);
    };

    const handlePasswordsMatch = () => {
        if (newPassword !== confirmedPassword) {
            setError('Passwords do not match');
            return false;
        } else {
            setError(null);
            return true;
        }
    };

    const handleConfirm = async (e: React.FormEvent) => {
        e.preventDefault();
        const passwordsMatch = handlePasswordsMatch();
        if (!passwordsMatch) {
            return;
        }
        const data = await callApi({
            url: `/api/auth/reset-password/${uuid}`,
            body: { username, newPassword, isAdminRequest },
            method: 'POST',
            exposeError: true
        });
        if (!data || !data.message) {
            return;
        }
        setStatus({ type: 'success', message: data.message });
        const usernameSnippet = data?.user?.SamAccountName ? (
            <div>
                Your username is <b>VINDASHBOARD\{data.user.SamAccountName}</b>
            </div>
        ) : (
            ''
        );
        alertModal({
            title: 'Password Updated',
            content: (
                <Typography>
                    Your password has been updated. {usernameSnippet} You may now{' '}
                    <a href='https://myaccount.winepulse.com/Reports/' target='_blank' rel='noreferrer'>
                        log in with your new password.
                    </a>{' '}
                </Typography>
            ),
            onClose: () => {
                navigate('/auth/login');
            }
        });
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!loading && !validToken) {
        return (
            <AlertModal
                title='Invalid Link'
                content={
                    <Typography>
                        The password reset link is invalid. Please request a new password reset link.
                    </Typography>
                }
                onClose={() => navigate('/auth/forgot-password')}
            />
        );
    }

    const prompt = isWelcome
        ? 'Welcome! Please choose your password.'
        : 'Please enter your username and choose a new password.';

    return (
        <div
            id='ResetPassword'
            style={{
                width: '100vw',
                height: '100dvh',
                backgroundColor: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <form autoComplete='on' onSubmit={handleConfirm}>
                <Stack
                    spacing={2}
                    direction='column'
                    textAlign='center'
                    alignItems='center'
                    sx={{ width: '100%', maxWidth: '350px' }}
                >
                    <Stack direction='row' display='flex' justifyContent='center'>
                        <img
                            src='/assets/logowinepulse.png'
                            alt='logo'
                            style={{ width: '10rem', height: '10rem', marginTop: '20px' }}
                        />
                    </Stack>
                    <Typography
                        variant='body1'
                        sx={{ color: 'black', fontFamily: 'Noto Sans, sans-serif', width: '350px', mb: '20px' }}
                    >
                        {prompt}
                    </Typography>
                    <ThemeProvider theme={theme}>
                        {!isWelcome && (
                            <TextField
                                id='username'
                                required
                                autoComplete='on'
                                value={username}
                                onChange={handleUsernameChange}
                                type='text'
                                label='Username'
                                variant='outlined'
                                color='primary'
                                sx={{ width: 'calc(100% - 20px)' }}
                            />
                        )}
                        <TextField
                            id='new-password'
                            required
                            autoComplete='on'
                            value={newPassword}
                            onChange={handleNewPasswordChange}
                            type={showPassword ? 'text' : 'password'}
                            label='New Password'
                            variant='outlined'
                            color='primary'
                            sx={{ width: 'calc(100% - 20px)' }}
                            InputProps={{
                                endAdornment: (
                                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                                        {showPassword ? (
                                            <VisibilityOff fontSize='small' />
                                        ) : (
                                            <Visibility fontSize='small' />
                                        )}
                                    </IconButton>
                                )
                            }}
                        />
                        <TextField
                            id='confirmed-password'
                            required
                            autoComplete='on'
                            value={confirmedPassword}
                            onChange={handleConfirmPasswordChange}
                            type={showPassword ? 'text' : 'password'}
                            label='Confirm New Password'
                            variant='outlined'
                            color='primary'
                            sx={{ width: 'calc(100% - 20px)' }}
                            error={!!error}
                            helperText={error}
                            onBlur={handlePasswordsMatch}
                            InputProps={{
                                endAdornment: (
                                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                                        {showPassword ? (
                                            <VisibilityOff fontSize='small' />
                                        ) : (
                                            <Visibility fontSize='small' />
                                        )}
                                    </IconButton>
                                )
                            }}
                        />
                        <Button
                            variant='contained'
                            type='submit'
                            color='primary'
                            sx={{
                                width: 'calc(100% - 20px)',
                                height: '50px',
                                top: '10px'
                            }}
                        >
                            Continue
                        </Button>
                    </ThemeProvider>
                </Stack>
            </form>
        </div>
    );
};

export default ResetPassword;
