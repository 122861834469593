import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Button,
    Box,
    Divider,
    Grid,
    Typography,
    FormControl,
    InputLabel,
    MenuItem,
    Checkbox,
    CircularProgress,
    Tooltip,
    IconButton
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { User, Winery } from './';
import useApi from '../../hooks/api';
import { useUser } from '../../contexts/user';
import { useNavigate, useParams } from 'react-router-dom';
import { Clear, Delete, Login, WineBar } from '@mui/icons-material';
import { useStatus } from '../../contexts/status';
import WineryStaticName from './WineryStaticName';
import { useSignIn } from 'react-auth-kit';
import { REACT_APP_CHAT_DTC_URL } from '../../constants/api';
import DropDownMenu from '../../components/DropDownMenu';
import Permissions from '../../components/Permissions';

interface FormDialogProps {
    getUsers: () => void;
}
const emptyWineryState = {
    Winery_id: '',
    ChatDTC: true,
    Analytics: true
};
export const emptyUser: User = {
    SamAccountName: '',
    Email: '',
    FirstName: '',
    LastName: '',
    UserRole: '',
    Created: new Date(),
    LastModified: new Date(),
    FullName: '',
    Sys_Admin: false,
    Password_Reset_Token: '',
    Password_Reset_Token_Expires: new Date()
};

export default function FormDialog({ getUsers }: FormDialogProps) {
    const params = useParams();
    const navigate = useNavigate();
    const { user: loggedInUser } = useUser();
    const { Wineries } = loggedInUser;
    const [formData, setFormData] = useState<User>(emptyUser);
    const { setStatus } = useStatus();
    const { callApi, loading } = useApi();
    const { action, SamAccountName } = params;
    const singleWinery = Wineries && Wineries.length === 1 && Wineries[0];
    const signIn = useSignIn();
    const { setUser } = useUser();

    // get union/merged wineries of Wineries and formData.Wineries
    const wineriesMap: { [key: string]: Winery } = {};
    [...(Wineries || []), ...(formData.Wineries || [])].forEach(winery => {
        if (!wineriesMap[winery.Winery_id]) {
            wineriesMap[winery.Winery_id] = winery;
        }
    });
    const unionOfWineriesForLoggedInUserAndEditingUser = Object.values(wineriesMap);

    const open = !!action;
    const isEditing = action === 'edit';
    const isDelete = action === 'delete';
    const canDelete = isEditing && (loggedInUser?.Winery_Admin || loggedInUser?.Sys_Admin);
    // non-Sys_Admin users can't edit Sys_Admin users
    const canEdit = loggedInUser.Sys_Admin || !formData.Sys_Admin;
    const isSameUser = loggedInUser.SamAccountName === SamAccountName;

    const getUser = async (username?: String) => {
        const url = `/api/users/${username || SamAccountName}`;
        try {
            const user = await callApi({ url, exposeError: true });
            if (user) {
                setFormData(user);
            }
        } catch (error) {
            setStatus({ type: 'error', message: 'Error fetching user' });
        }
    };

    useEffect(() => {
        if (action === 'new') {
            setFormData({ ...emptyUser, Wineries: [singleWinery || emptyWineryState] });
        } else if (action === 'edit' && SamAccountName) {
            getUser();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action, SamAccountName]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleWineryChange = (e: SelectChangeEvent<string>, idx: number) => {
        const { value } = e.target;

        const newWineries = formData.Wineries?.map((winery, i) => {
            if (i === idx) {
                return {
                    ...winery,
                    Winery_id: value,
                    // only set to true if it's selected and the winery has access to that platform
                    ChatDTC: wineriesMap[value]?.ChatDTC_Access && winery.ChatDTC,
                    Analytics: wineriesMap[value]?.Analytics_Access && winery.Analytics
                };
            }
            return winery;
        });

        setFormData({
            ...formData,
            Wineries: newWineries
        });
    };

    const handleRemoveWinery = (e: React.MouseEvent<HTMLButtonElement>, idx: number) => {
        e.preventDefault();
        const newWineries = formData.Wineries?.filter((winery, i) => i !== idx);
        setFormData({
            ...formData,
            Wineries: newWineries
        });
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
        const { name, checked } = e.target;

        const newWineries = formData.Wineries?.map((winery, i) => {
            if (i === idx) {
                return {
                    ...winery,
                    [name]: checked
                };
            }
            return winery;
        });

        setFormData({
            ...formData,
            Wineries: newWineries
        });
    };

    const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        handleAddOrEditUser(formData);
    };

    const handleAddOrEditUser = async (data: User) => {
        // ensure no duplicate wineries
        const wineryIds = formData.Wineries?.map(winery => winery.Winery_id);
        const uniqueWineryIds = Array.from(new Set(wineryIds));
        if (wineryIds && wineryIds.length !== uniqueWineryIds.length) {
            setStatus({ type: 'error', message: 'Duplicate wineries are not allowed' });
            return;
        }

        // call api
        const url = isEditing ? `/api/users/${formData?.SamAccountName}` : '/api/users';
        const userData = await callApi({
            url,
            method: isEditing ? 'PATCH' : 'POST',
            body: data,
            exposeError: true
        });

        // handle response
        if (!userData || !userData.SamAccountName) {
            return;
        }
        setStatus({ type: 'success', message: `User ${isEditing ? 'Saved' : 'Added'}` });
        getUsers();
        getUser(userData.SamAccountName);
        handleClose();
    };
    const handleClose = () => {
        navigate('/admin/users');
    };

    const handleLoginAsUser = async (SamAccountName: string, app?: 'chatdtc' | 'admin') => {
        const data = await callApi({
            url: `/api/auth/login-as/${SamAccountName}`,
            method: 'POST',
            exposeError: true
        });
        if (data && data.message === 'Login successful') {
            if (app === 'chatdtc') {
                window.open(`${REACT_APP_CHAT_DTC_URL}/auth/login-as?token=${data.token}`);
                return;
            }
            if (
                signIn({
                    token: data.token,
                    expiresIn: 28800,
                    tokenType: 'Bearer',
                    authState: {
                        email: data.user.Email,
                        username: data.user.SamAccountName
                    }
                })
            ) {
                const userData = {
                    ...data?.user,
                    isAtLeastWineryAdmin: data.user.Sys_Admin || data.user.Winery_Admin
                };
                setUser(userData);
                navigate('/');
            } else {
                console.log('Login failed');
                setStatus({ type: 'error', message: 'Error with Login' });
            }
        }
    };

    const handleSendPasswordResetEmail = async () => {
        const data = await callApi({
            url: `/api/auth/send-password-reset-email/${formData.SamAccountName}`,
            method: 'POST',
            exposeError: true
        });
        if (data && data.message === 'Password reset email sent') {
            setStatus({ type: 'success', message: 'Password reset email sent' });
        }
    };

    return (
        <>
            {/* confirm delete dialog */}
            <Dialog
                open={isDelete}
                onClose={() => navigate(`/admin/users/edit/${SamAccountName}`)}
                sx={{ zIndex: '2000' }}
            >
                <DialogTitle>Delete User</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to delete {formData.FirstName} {formData.LastName}?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => navigate(`/admin/users/edit/${SamAccountName}`)}>Cancel</Button>
                    <Button
                        onClick={async () => {
                            const res = await callApi({
                                url: `/api/users/${formData.SamAccountName}`,
                                method: 'DELETE',
                                exposeError: true
                            });
                            console.log('>>>>> res', res);
                            if (res || res === '') {
                                setStatus({ type: 'success', message: 'User deleted' });
                                navigate('/admin/users');
                                getUsers();
                            } else {
                                navigate(`/admin/users/edit/${SamAccountName}`);
                            }
                        }}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            {/* create / edit dialog */}
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth='sm'
                fullWidth
                PaperProps={{
                    component: 'form',
                    onSubmit: handleFormSubmit
                }}
            >
                <DialogTitle>
                    <Grid container spacing={3} direction='row' justifyContent='space-between'>
                        <Grid item>
                            <Typography variant='h6' color='primary' gutterBottom>
                                {formData.SamAccountName || 'New User'}
                            </Typography>
                        </Grid>
                        {!isSameUser && (
                            <>
                                <Grid
                                    item
                                    textAlign='right'
                                    display='flex'
                                    direction='row'
                                    justifyContent='end'
                                    alignItems='center'
                                    gap={2}
                                >
                                    <Permissions requireRoles={['wineryAdmin']}>
                                        {isEditing && (
                                            <Button
                                                variant='outlined'
                                                color='primary'
                                                onClick={() => handleSendPasswordResetEmail()}
                                            >
                                                Reset Password
                                            </Button>
                                        )}
                                    </Permissions>
                                    <Permissions requireRoles={['sysAdmin']}>
                                        <DropDownMenu
                                            title=''
                                            idName='login-as-user'
                                            startIcon={<Login />}
                                            options={[
                                                {
                                                    label: 'Log In as User - Admin Portal (this app)',
                                                    onClick: () => handleLoginAsUser(formData.SamAccountName, 'admin')
                                                },
                                                {
                                                    label: 'Log In as User - ChatDTC',
                                                    onClick: () => handleLoginAsUser(formData.SamAccountName, 'chatdtc')
                                                }
                                            ]}
                                        ></DropDownMenu>
                                    </Permissions>

                                    {canEdit && canDelete && (
                                        <Tooltip title='Delete User'>
                                            <IconButton
                                                color='error'
                                                onClick={() =>
                                                    navigate(`/admin/users/delete/${formData.SamAccountName}`)
                                                }
                                            >
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Grid>
                            </>
                        )}
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    {loading ? (
                        <CircularProgress color='inherit' />
                    ) : (
                        <Box flex='1' mt={-1}>
                            <Box display='flex' width={430} mb={2}>
                                <TextField
                                    type='text'
                                    id='FirstName'
                                    name='FirstName'
                                    value={formData.FirstName}
                                    onChange={handleInputChange}
                                    autoFocus
                                    required
                                    fullWidth
                                    margin='dense'
                                    label='First Name'
                                    variant='standard'
                                />
                                <Spacer />
                                <TextField
                                    type='text'
                                    id='LastName'
                                    name='LastName'
                                    value={formData.LastName}
                                    onChange={handleInputChange}
                                    required
                                    fullWidth
                                    margin='dense'
                                    label='Last Name'
                                    variant='standard'
                                />
                            </Box>
                            <Box display='flex' width={430} mt={2} mb={2}>
                                <TextField
                                    type='email'
                                    id='Email'
                                    name='Email'
                                    value={formData.Email}
                                    onChange={handleInputChange}
                                    required
                                    fullWidth
                                    margin='dense'
                                    label='Email'
                                    variant='standard'
                                />
                            </Box>
                            {(!isSameUser || loggedInUser.Sys_Admin) && (
                                <>
                                    <Divider />
                                    <Box mt={2}>
                                        {/* Add winery button */}
                                        <Box display='flex' justifyContent='space-between'>
                                            <Typography variant='h6' color='primary' gutterBottom>
                                                Platform Access
                                            </Typography>
                                            {!singleWinery && (
                                                <Button
                                                    variant='outlined'
                                                    color='primary'
                                                    startIcon={<WineBar />}
                                                    onClick={() =>
                                                        setFormData(prevData => ({
                                                            ...prevData,
                                                            Wineries: [...(prevData.Wineries || []), emptyWineryState]
                                                        }))
                                                    }
                                                >
                                                    Add Winery
                                                </Button>
                                            )}
                                        </Box>

                                        {/* Winery & Role*/}

                                        {formData.Wineries?.map((winery, idx) => {
                                            const wineryFromUser: Winery = Wineries?.find(
                                                w => w.Winery_id === winery.Winery_id
                                            ) || { Winery_id: '' };

                                            const wineryFromAllWineries: Winery =
                                                unionOfWineriesForLoggedInUserAndEditingUser?.find(
                                                    w => w.Winery_id === winery.Winery_id
                                                ) || { Winery_id: '' };

                                            const loggedInUserHasAccessToWinery = !!wineryFromUser?.Winery_id;

                                            const wineryHasBeenSelected = !!winery.Winery_id;

                                            const loggedInUserDoesNotHaveAccess =
                                                !loggedInUserHasAccessToWinery && wineryHasBeenSelected;

                                            const checkboxes = ['Analytics', 'ChatDTC'].map((platform, i) => {
                                                const wineryDoesNotHavePlatformAccess =
                                                    !wineryFromAllWineries[
                                                        `${platform}_Access` as keyof typeof wineryFromAllWineries
                                                    ];

                                                const checkbox = (
                                                    <Box
                                                        display='flex'
                                                        key={`${idx}-${i}`}
                                                        alignItems='center'
                                                        justifyContent='center'
                                                        width={110}
                                                    >
                                                        <Checkbox
                                                            name={platform}
                                                            checked={!!winery[platform as keyof typeof winery]}
                                                            onChange={e => handleCheckboxChange(e, idx)}
                                                            disabled={
                                                                wineryDoesNotHavePlatformAccess ||
                                                                !loggedInUserHasAccessToWinery
                                                            }
                                                        />
                                                        <InputLabel htmlFor={platform}>{platform}</InputLabel>
                                                    </Box>
                                                );

                                                if (wineryDoesNotHavePlatformAccess) {
                                                    return <Box key={`${idx}-${i}`} width={110} />;
                                                }

                                                if (loggedInUserDoesNotHaveAccess) {
                                                    return (
                                                        <Tooltip
                                                            key={`${idx}-${i}`}
                                                            title={`You do not have access to this winery`}
                                                            placement='top'
                                                        >
                                                            {checkbox}
                                                        </Tooltip>
                                                    );
                                                }

                                                return checkbox;
                                            });

                                            const renderWineryNameOrSelect = () => {
                                                if (loggedInUserDoesNotHaveAccess) {
                                                    return (
                                                        <WineryStaticName
                                                            disabled
                                                            wineryName={winery.Winery_Name || ''}
                                                        />
                                                    );
                                                }

                                                if (singleWinery) {
                                                    return (
                                                        <WineryStaticName
                                                            wineryName={wineryFromUser.Winery_Name || ''}
                                                        />
                                                    );
                                                }

                                                return (
                                                    <FormControl sx={{ mt: 2, minWidth: 250 }}>
                                                        <InputLabel htmlFor='Winery'>Winery</InputLabel>
                                                        <Select
                                                            name='Winery'
                                                            value={winery.Winery_id}
                                                            defaultValue='banner'
                                                            onChange={e => handleWineryChange(e, idx)}
                                                            label='Winery'
                                                            disabled={loggedInUserDoesNotHaveAccess}
                                                        >
                                                            {Wineries?.map((winery: any, index: number) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={`${idx}-${index}`}
                                                                        value={winery.Winery_id}
                                                                    >
                                                                        {winery.Winery_Name}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                );
                                            };

                                            return (
                                                <Box display='flex' width={570} mt={2} mb={2} key={idx}>
                                                    {renderWineryNameOrSelect()}
                                                    <Box
                                                        display='flex'
                                                        width={600}
                                                        mt={2}
                                                        key={idx}
                                                        alignItems='center'
                                                    >
                                                        <Spacer />

                                                        {checkboxes}

                                                        <Spacer />

                                                        {/* Delete button */}
                                                        {!loggedInUserDoesNotHaveAccess && !singleWinery && (
                                                            <Button
                                                                variant='text'
                                                                color='primary'
                                                                onClick={e => handleRemoveWinery(e, idx)}
                                                            >
                                                                <Clear />
                                                            </Button>
                                                        )}
                                                    </Box>
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                </>
                            )}
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type='submit'>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

const Spacer = () => <Box width={20} component='span' />;
