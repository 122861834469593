import { Tooltip, Typography } from '@mui/material';
import theme from '../../theme';

interface WineryStaticNameProps {
    wineryName: string;
    disabled?: boolean;
}
const WineryStaticName = ({ wineryName, disabled }: WineryStaticNameProps) => {
    const text = (
        <Typography
            sx={{
                marginTop: '16px',
                width: '445px',
                padding: '16.5px 14px',
                height: '3.5em',
                border: `1px solid ${theme.palette.grey[300]}`,
                borderRadius: '4px',
                marginRight: '0px',
                color: theme.palette.grey[600]
            }}
        >
            {wineryName}
        </Typography>
    );
    return disabled ? (
        <Tooltip title={`You do not have access to this winery`} placement='top'>
            {text}
        </Tooltip>
    ) : (
        text
    );
};

export default WineryStaticName;
