import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import useApi from '../../hooks/api';
import { Circle, MoreVert, Edit, DeleteOutline, NotificationAdd, Campaign } from '@mui/icons-material';
import theme from '../../theme';
import { Button, Grid, Typography, IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Chip } from '@mui/material';
import FormDialog from './FormDialog';

export interface Notification {
    id: number;
    Content?: string;
    Type?: string;
    Severity?: 'error' | 'warning' | 'info'; // "error", "warning", "info"
    Active?: boolean;
    Effective_Start_Date?: Date;
    Effective_End_Date?: Date | null;
    Created_Date?: Date;
    Updated_Date?: Date;
}

export const emptyNotification: Partial<Notification> = {
    Content: '',
    Type: 'banner',
    Severity: 'warning',
    Active: true,
    Effective_Start_Date: new Date(),
    Effective_End_Date: null
};

const formatDate = (date?: Date | null) => {
    if (!date) {
        return '--';
    }
    const dateified = new Date(date);
    return new Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
    }).format(dateified);
};

const StatusFormatted = ({ Active }: Pick<Notification, 'Active'>) => {
    const FormattedCircle = () => {
        return <Circle sx={{ fontSize: '6px', color: Active ? 'green' : theme.palette.error.main }} />;
    };
    return Active ? (
        <>
            <FormattedCircle /> <Chip label='ACTIVE' color='success' variant='outlined' size='small' />
        </>
    ) : (
        <>
            <FormattedCircle /> <Chip label='INACTIVE' color='error' variant='outlined' size='small' />
        </>
    );
};

export default function NotificationsList() {
    const { callApi, callApiLazy, data } = useApi();
    const [open, setOpen] = React.useState(false);
    const [editingNotification, setEditingNotification] = React.useState<Partial<Notification> | undefined>(undefined);

    const getNotifications = () => {
        callApiLazy({ url: '/api/notification' });
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const actionsMenuOpen = Boolean(anchorEl);
    const handleOpenActionsMenu = (event: React.MouseEvent<HTMLElement>, notification: Notification) => {
        setEditingNotification(notification);
        setAnchorEl(event.currentTarget);
    };

    const handleCloseActionsMenu = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        setOpen(true);
        handleCloseActionsMenu();
    };

    const handleUpdateActive = async (active: boolean) => {
        await callApi({
            url: `/api/notification/${editingNotification?.id}`,
            method: 'PUT',
            body: {
                ...editingNotification,
                Active: active
            }
        });

        getNotifications();
        handleCloseActionsMenu();
    };

    const options = [
        {
            title: 'Edit',
            handleClick: () => handleEdit(),
            icon: <Edit fontSize='small' />
        },
        {
            title: 'Deactivate',
            handleClick: () => handleUpdateActive(false),
            icon: <DeleteOutline fontSize='small' />,
            color: theme.palette.error.main
        },
        {
            title: 'Activate',
            handleClick: () => handleUpdateActive(true),
            icon: <Campaign fontSize='small' />,
            color: theme.palette.success.main
        }
    ];
    const notifications: Notification[] = data?.notifications || [];
    const cleanedNotifications = notifications.map(notification => {
        const cleanedNotification = {
            ...notification,
            // First 10 letters of Content
            Active: <StatusFormatted Active={notification.Active} />,
            Content: notification.Content?.slice(0, 35) + (Number(notification.Content?.length) > 25 ? '...' : ''),
            Effective_Start_Date: formatDate(notification.Effective_Start_Date),
            Effective_End_Date: formatDate(notification.Effective_End_Date),
            Created_Date: formatDate(notification.Created_Date),
            Updated_Date: formatDate(notification.Updated_Date)
        };
        return cleanedNotification;
    });

    React.useEffect(() => {
        getNotifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClickOpen = () => {
        setEditingNotification({ ...emptyNotification });
        setOpen(true);
    };

    return (
        <>
            <FormDialog
                open={open}
                setOpen={setOpen}
                notification={editingNotification}
                getNotifications={getNotifications}
            />
            <Grid container spacing={3} direction='row'>
                <Grid item xs={9}>
                    <Typography component='h2' variant='h6' color='primary' gutterBottom>
                        Notifications
                    </Typography>
                </Grid>
                <Grid item xs={3} textAlign='right'>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={handleClickOpen}
                        startIcon={<NotificationAdd />}
                    >
                        Create New
                    </Button>
                </Grid>
            </Grid>
            <Table size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell>Severity</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Content</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Start</TableCell>
                        <TableCell>End</TableCell>
                        <TableCell>Last Updated</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {cleanedNotifications.map(notification => (
                        <TableRow key={notification.id}>
                            <TableCell>
                                {notification.Severity && (
                                    <Chip
                                        size='small'
                                        label={notification.Severity}
                                        color={notification.Severity || 'primary'}
                                        variant='outlined'
                                    />
                                )}
                            </TableCell>
                            <TableCell>{notification.Type}</TableCell>
                            <TableCell>{notification.Content}</TableCell>
                            <TableCell sx={{ fontSize: '.75em' }}>{notification.Active}</TableCell>
                            <TableCell>{notification.Effective_Start_Date}</TableCell>
                            <TableCell>{notification.Effective_End_Date}</TableCell>
                            <TableCell>{notification.Updated_Date}</TableCell>
                            <TableCell>
                                <IconButton
                                    aria-label='more'
                                    id='notification-actions-button'
                                    aria-controls={actionsMenuOpen ? 'notification-actions-menu' : undefined}
                                    aria-expanded={actionsMenuOpen ? 'true' : undefined}
                                    aria-haspopup='true'
                                    onClick={e =>
                                        handleOpenActionsMenu(
                                            e,
                                            notifications.find(n => n.id === notification.id) as Notification
                                        )
                                    }
                                >
                                    <MoreVert />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Menu
                id='chat-actions-menu'
                MenuListProps={{
                    'aria-labelledby': 'chat-actions-button'
                }}
                anchorEl={anchorEl}
                open={actionsMenuOpen}
                onClose={handleCloseActionsMenu}
                sx={{
                    transform: 'translateY(-10px)',
                    '& .MuiMenu-paper': {
                        border: '1px solid #d3d4d5'
                    }
                }}
            >
                {options.map(option => (
                    <MenuItem key={option.title} onClick={option.handleClick}>
                        <ListItemIcon sx={{ color: option.color }}>{option.icon}</ListItemIcon>
                        <ListItemText sx={{ color: option.color }}>{option.title}</ListItemText>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}
