import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { mainListItems, adminItems, sysAdminItems } from './ListItems';
import { useTitle } from '../../contexts/title';
import Permissions from '../Permissions';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: prop => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
    '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        boxSizing: 'border-box',
        ...(!open && {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9)
            }
        })
    }
}));

interface NavBarProps {}

export default function NavBar({ children }: React.PropsWithChildren<NavBarProps>) {
    const [open, setOpen] = React.useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const { title } = useTitle();

    useEffect(() => {
        if (window.innerWidth < 600) {
            setOpen(false);
        }
    }, []);

    return (
        <>
            <CssBaseline />
            <AppBar position='absolute' open={open}>
                <Toolbar
                    sx={{
                        pr: '24px' // keep right padding when drawer closed
                    }}
                >
                    <IconButton
                        edge='start'
                        color='inherit'
                        aria-label='open drawer'
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '16px'
                            // ...(open && { display: 'none' })
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Grid container gap={2}>
                        <Grid item>
                            <Grid container gap={1} alignItems='center'>
                                <Link
                                    to='/'
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '8px',
                                        textDecoration: 'none'
                                    }}
                                >
                                    <img
                                        src='/assets/logowinepulse-round.png'
                                        alt='logo'
                                        style={{ width: '40px', height: '40px' }}
                                    />
                                    <Typography component='h1' variant='h6' color='white' noWrap sx={{ flexGrow: 1 }}>
                                        <em>Wine</em>
                                        <b>Pulse</b>
                                    </Typography>
                                </Link>
                                <Grid item>
                                    <Typography
                                        component='h1'
                                        variant='h6'
                                        color='inherit'
                                        noWrap
                                        sx={{ flexGrow: 1, marginLeft: '24px' }}
                                    >
                                        {title}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <IconButton color='inherit'>
                        <Link
                            to='/user/profile'
                            style={{
                                color: 'white',
                                textDecoration: 'none',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <Badge badgeContent={4} color='secondary'>
                            <Notifications />
                        </Badge>
                            <AccountCircle />
                        </Link>
                    </IconButton> */}
                </Toolbar>
            </AppBar>
            <Drawer variant='permanent' open={open}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1]
                    }}
                >
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <List component='nav'>
                    {mainListItems}
                    <Permissions requireRoles={['wineryAdmin']}>
                        <Divider sx={{ my: 1 }} />
                        {adminItems}
                        <Permissions requireRoles={['sysAdmin']}>{sysAdminItems}</Permissions>
                    </Permissions>
                </List>
            </Drawer>
            {children}
        </>
    );
}
