import { Button, Grid, Modal, Typography } from '@mui/material';
import { Box } from '@mui/material';
import { useState } from 'react';
import styled from 'styled-components';

const StyledModalBox = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 400px;
    max-width: 500px;
    background-color: #fff;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15); /* Assuming '24' is the blur radius and the color is black with 15% opacity */
    padding: 16px; /* Assuming '4' is a spacing unit equivalent to 16px */
`;

const WpModal = ({
    open,
    onClose,
    title,
    children
}: {
    open: boolean;
    onClose: () => void;
    title: string;
    children: React.ReactNode;
}) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <StyledModalBox>
                <Typography id='modal-modal-title' variant='h6' component='h2'>
                    {title}
                </Typography>
                <div id='modal-modal-description' style={{ marginTop: '12px' }}>
                    {children}
                </div>
            </StyledModalBox>
        </Modal>
    );
};

interface AlertModalProps {
    title: string;
    content: React.ReactNode;
    onClose?: () => void;
    okButton?: boolean;
}
export const AlertModal = ({ title, content, onClose, okButton = true }: AlertModalProps) => {
    const [open, setOpen] = useState(true);
    const handleOk = () => {
        setOpen(false);
        if (onClose) {
            onClose();
        }
    };
    return (
        <WpModal open={open} title={title} onClose={handleOk}>
            {content}
            {okButton && (
                <Grid container gap={2} direction='row' justifyContent='flex-end'>
                    <Button variant='outlined' color='primary' onClick={handleOk}>
                        OK
                    </Button>
                </Grid>
            )}
        </WpModal>
    );
};

export default WpModal;
