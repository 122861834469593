import React, { ReactNode, createContext, useContext } from 'react';
import { useState } from 'react';

const LoadingContext = createContext({
    loading: false,
    setLoading: (loading: boolean) => {}
});

interface Props {
    children?: ReactNode;
}

const LoadingProvider = ({ children }: Props) => {
    const [loading, setLoading] = useState(false);

    return <LoadingContext.Provider value={{ loading, setLoading }}>{children}</LoadingContext.Provider>;
};

const useLoading = () => {
    return useContext(LoadingContext);
};

export { LoadingProvider, useLoading };
