import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Button,
    FormControl,
    Select,
    FormControlLabel,
    MenuItem,
    InputLabel,
    Switch,
    Grid
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { Notification, emptyNotification } from './NotificationsList';
import useApi from '../../hooks/api';
import CalendarDatePicker from './DatePicker';
import dayjs, { Dayjs } from 'dayjs';

interface FormDialogProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    notification?: Partial<Notification>;
    getNotifications: () => void;
}
export default function FormDialog({ open, setOpen, notification, getNotifications }: FormDialogProps) {
    const [formData, setFormData] = useState<Partial<Notification>>(emptyNotification);

    const isEditing = notification?.id !== undefined;
    const isEditingEnd = Boolean(formData?.Effective_End_Date);
    const startOfTomorrow = dayjs().add(1, 'day').startOf('day');

    useEffect(() => {
        if (notification) {
            setFormData({
                Content: notification.Content,
                Type: notification.Type,
                Severity: notification.Severity,
                Active: notification.Active,
                Effective_Start_Date: notification.Effective_Start_Date,
                Effective_End_Date: notification.Effective_End_Date
            } as Notification);
        }
    }, [notification]);
    const { callApi } = useApi();

    const handleDateChange = (fieldName: string, date?: Dayjs | null) => {
        setFormData(prevData => ({
            ...prevData,
            [fieldName]: date?.toDate()
        }));
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSelectChange = (e: SelectChangeEvent<string>) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: checked
        }));
    };

    const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        handleSubmit(formData);
    };
    const handleSubmit = async (data: Partial<Notification>) => {
        const url = isEditing ? `/api/notification/${notification?.id}` : '/api/notification';
        await callApi({
            url,
            method: isEditing ? 'PUT' : 'POST',
            body: data
        });
        getNotifications();
        handleClose();
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth='sm'
                fullWidth
                PaperProps={{
                    component: 'form',
                    onSubmit: handleFormSubmit
                }}
            >
                <DialogTitle>Notification</DialogTitle>
                <DialogContent>
                    <DialogContentText>Enter notification details</DialogContentText>
                    <TextField
                        type='text'
                        id='Content'
                        name='Content'
                        multiline
                        placeholder='Content'
                        value={formData.Content}
                        onChange={handleInputChange}
                        autoFocus
                        required
                        fullWidth
                        margin='dense'
                        label='Content'
                        variant='standard'
                    />

                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={5}>
                            <CalendarDatePicker
                                label='Start Time'
                                value={formData?.Effective_Start_Date}
                                setValue={(date: Dayjs | null) => handleDateChange('Effective_Start_Date', date)}
                            />
                        </Grid>
                        {isEditingEnd ? (
                            <Grid item xs={5}>
                                <CalendarDatePicker
                                    label='End Time'
                                    value={formData?.Effective_End_Date}
                                    setValue={(date: Dayjs | null) => handleDateChange('Effective_End_Date', date)}
                                />
                            </Grid>
                        ) : (
                            <Button onClick={() => handleDateChange('Effective_End_Date', startOfTomorrow)}>
                                Edit End Time
                            </Button>
                        )}
                    </Grid>

                    <FormControl sx={{ mt: 2, minWidth: 120 }}>
                        <InputLabel htmlFor='Type'>Type</InputLabel>
                        <Select
                            name='Type'
                            value={formData.Type}
                            defaultValue='banner'
                            onChange={handleSelectChange}
                            label='Type'
                        >
                            <MenuItem value='banner'>banner</MenuItem>
                            <MenuItem value='modal'>modal</MenuItem>
                        </Select>
                    </FormControl>
                    <br />
                    <FormControl sx={{ mt: 2, minWidth: 120 }}>
                        <InputLabel htmlFor='Severity'>Severity</InputLabel>
                        <Select
                            name='Severity'
                            value={formData.Severity}
                            defaultValue='warning'
                            onChange={handleSelectChange}
                            label='Severity'
                        >
                            <MenuItem value='warning'>warning</MenuItem>
                            <MenuItem value='info'>info</MenuItem>
                            <MenuItem value='error'>error</MenuItem>
                        </Select>
                    </FormControl>
                    <br />
                    <FormControlLabel
                        sx={{ mt: 1 }}
                        control={<Switch name='Active' checked={formData.Active} onChange={handleSwitchChange} />}
                        label={notification?.Active ? 'Active' : 'Inactive'}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type='submit'>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
