import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import { Logout } from '@mui/icons-material';
import { useStatus } from '../../contexts/status';
import { Link, useNavigate } from 'react-router-dom';
import { useSignOut } from 'react-auth-kit';
import { useUser } from '../../contexts/user';
import MetaTitle from '../../components/MetaTitle';

export default function Profile() {
    const signOut = useSignOut();
    const { user } = useUser();

    const { setStatus } = useStatus();
    const navigate = useNavigate();
    const handleLogOut = () => {
        try {
            signOut();
            navigate('/auth/login');
            setStatus({
                type: 'success',
                message: 'You have been successfully logged out.'
            });
        } catch (error) {
            if (error instanceof Error)
                setStatus({
                    type: 'error',
                    message: error?.message || 'Problem logging out. Please refresh the page and try again.'
                });
        }
    };
    return (
        <Box
            component='main'
            sx={{
                backgroundColor: theme =>
                    theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto'
            }}
        >
            <MetaTitle title='Profile' />
            <Toolbar />
            <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                            <Typography component='h2' variant='h6' color='primary' gutterBottom>
                                Profile
                            </Typography>
                            {/* log out button with icon */}
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    You are signed in as <Typography variant='h6'>{user?.SamAccountName}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant='outlined'
                                        color='primary'
                                        sx={{ marginRight: '8px' }}
                                        onClick={() => navigate(`/admin/users/edit/${user?.SamAccountName}`)}
                                    >
                                        Edit Profile
                                    </Button>
                                    <Button
                                        variant='contained'
                                        color='secondary'
                                        startIcon={<Logout />}
                                        onClick={handleLogOut}
                                    >
                                        Logout
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
