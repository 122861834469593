import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTitle } from '../contexts/title';
import { APP_TITLE } from '../constants';

interface TitleProps {
    title: string;
}

const MetaTitle: React.FC<TitleProps> = ({ title }) => {
    const { setTitle } = useTitle();

    useEffect(() => {
        setTitle(title);
        return () => setTitle(APP_TITLE);
    }, [title, setTitle]);

    return (
        <Helmet>
            <title>
                {title} | {APP_TITLE}
            </title>
        </Helmet>
    );
};

export default MetaTitle;
