import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimeField } from '@mui/x-date-pickers';

type DateType = Date | Dayjs | string | null | undefined;
interface CalendarDatePickerProps {
    label: string;
    value?: DateType;
    setValue: (_date: Dayjs | null) => void;
}
export default function CalendarDatePicker({ label, value, setValue }: CalendarDatePickerProps) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimeField label={label} value={dayjs(value)} onChange={newValue => setValue(newValue)} />
        </LocalizationProvider>
    );
}
