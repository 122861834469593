// TitleContext.tsx
import React, { createContext, useContext } from 'react';
import { APP_TITLE } from '../constants';

interface TitleContextType {
    title: string;
    setTitle: (title: string) => void;
}

const TitleContext = createContext<TitleContextType | undefined>(undefined);

export const useTitle = (): TitleContextType => {
    const context = useContext(TitleContext);
    if (!context) {
        throw new Error('useTitle must be used within a TitleProvider');
    }
    return context;
};

interface TitleProviderProps {
    children: React.ReactNode;
}

export const TitleProvider = ({ children }: TitleProviderProps) => {
    const [title, setTitle] = React.useState(APP_TITLE);

    return <TitleContext.Provider value={{ title, setTitle }}>{children}</TitleContext.Provider>;
};
