import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useAuthHeader } from 'react-auth-kit';
import { REACT_APP_API_URL } from '../constants/api';
import axios from 'axios';
import { Winery } from '../pages/Users';

export interface UserState {
    Last_Winery_id: string | null;
    Created?: string;
    Email?: string;
    FirstName?: string;
    FullName?: string;
    Wineries?: Winery[];
    LastWinery?: { [key: string]: any };
    LastModified?: string;
    LastName?: string;
    SamAccountName?: string;
    UserRole?: string;
    Default_Winery_id?: string;
    Sys_Admin?: boolean;
    Winery_Admin?: boolean;
    balance?: number; // their BillingBalance.Balance amount, or zero if they have no BillingBalance record
    isAtLeastWineryAdmin?: boolean;
}

const UserContext = createContext({
    user: { Last_Winery_id: null } as UserState,
    setUser: (user: UserState) => {},
    getUser: () => {},
    loading: true
});

interface Props {
    children?: ReactNode;
}

const UserProvider = ({ children }: Props) => {
    const authHeader = useAuthHeader();
    const [user, setUser] = useState<UserState>({ Last_Winery_id: null });
    const [loading, setLoading] = useState(true);

    const getUser = async () => {
        const Authorization = authHeader();
        if (!Authorization) {
            setLoading(false);
            return;
        }
        const res = await axios.get(`${REACT_APP_API_URL}/api/auth/me`, {
            headers: {
                Authorization
            }
        });
        const userData = res?.data?.user;
        if (userData) {
            userData.isAtLeastWineryAdmin = userData.Sys_Admin || userData.Winery_Admin;
            setUser(userData);
        }
        setLoading(false);
    };

    useEffect(() => {
        getUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <UserContext.Provider value={{ user, setUser, getUser, loading }}>{children}</UserContext.Provider>;
};

const useUser = () => {
    return useContext(UserContext);
};

export { UserProvider, useUser };
