import React, { createContext, useContext, useState } from 'react';
import WpModal from '../components/wpModal';
import { Button, Grid } from '@mui/material';

// context
interface AlertModalContextProps {
    alertModal: (options: {
        title: string;
        content: React.ReactNode;
        onClose?: () => void;
        onConfirm?: () => void;
        okText?: string;
        cancelText?: string;
    }) => void;
}

const AlertModalContext = createContext<AlertModalContextProps | undefined>(undefined);

interface AlertModalProviderProps {
    children: React.ReactNode;
}
// provider
export const AlertModalProvider = ({ children }: AlertModalProviderProps) => {
    const [modal, setModal] = useState<{
        title: string;
        content: React.ReactNode;
        onClose?: () => void;
        onConfirm?: () => void;
        okText?: string;
        cancelText?: string;
    } | null>(null);
    const [isOpen, setIsOpen] = useState(false);

    const alertModal = (options: {
        title: string;
        content: React.ReactNode;
        onClose?: () => void;
        okText?: string;
    }) => {
        setModal(options);
        setIsOpen(true);
    };

    const handleOk = () => {
        setIsOpen(false);
        if (modal?.onConfirm) {
            modal.onConfirm();
        }
        if (modal?.onClose) {
            modal.onClose();
        }
    };

    const handleCancel = () => {
        setIsOpen(false);
        if (modal?.onClose) {
            modal.onClose();
        }
    };

    return (
        <AlertModalContext.Provider value={{ alertModal }}>
            {children}
            {modal && (
                <WpModal open={isOpen} title={modal.title} onClose={handleCancel}>
                    {modal.content}
                    <Grid container gap={2} direction='row' justifyContent='flex-end' mt={2}>
                        {modal.cancelText && (
                            <Button variant='outlined' color='secondary' onClick={handleCancel}>
                                {modal.cancelText || 'Cancel'}
                            </Button>
                        )}
                        <Button variant='outlined' color='primary' onClick={handleOk}>
                            {modal.okText || 'OK'}
                        </Button>
                    </Grid>
                </WpModal>
            )}
        </AlertModalContext.Provider>
    );
};

// hook
export const useAlertModal = (): AlertModalContextProps => {
    const context = useContext(AlertModalContext);
    if (!context) {
        throw new Error('useAlertModal must be used within a AlertModalProvider');
    }
    return context;
};
