/* eslint-disable import/no-anonymous-default-export */
import * as React from 'react';
import {
    ListItem,
    ListItemAvatar,
    Avatar,
    Box,
    Grid,
    Toolbar,
    Container,
    Paper,
    ListItemText,
    Typography,
    Button,
    CircularProgress,
    TablePagination,
    TextField
} from '@mui/material';

import { Link, useNavigate, useLocation } from 'react-router-dom';

import useApi from '../../hooks/api';
import MetaTitle from '../../components/MetaTitle';
import FormDialog from './FormDialog';
import { PersonAdd } from '@mui/icons-material';
import WinerySelect from './WinerySelect';
import { debounce } from '../../util';

export interface User {
    SamAccountName: string;
    Email?: string;
    Default_Winery_id?: string;
    FirstName?: string;
    LastName?: string;
    UserRole?: string;
    Created?: Date;
    LastModified?: Date;
    UserPwd?: string;
    FullName?: string;
    Wineries?: Winery[];
    Last_Winery_id?: string;
    Sys_Admin?: boolean;
    Winery_Admin?: boolean;
    Password_Reset_Token?: string | null;
    Password_Reset_Token_Expires?: Date | null;
    // LastWinery?: WineryModel;
    // ThreadCategories?: ThreadCategoryModel[];
}

export interface Role {
    Role_id: string;
    Role_Name: string;
}

export interface Winery {
    Winery_id: string;
    Winery_Name?: string;
    Last_Sync_Date?: string;
    OpenAI_model?: string;
    POS_Name?: string;
    ChatDTC?: boolean; // whether or not the admin user has chosen to add user to this platform
    Analytics?: boolean; // whether or not the admin user has chosen to add user to this platform
    ChatDTC_Access?: boolean; // whether or not the admin user can add user to this platform
    Analytics_Access?: boolean; // whether or not the admin user can add user to this platform
}

interface SearchParams {
    search: string;
    wineries: string;
}

const Users = () => {
    const navigate = useNavigate();
    const { callApi, loading } = useApi();
    const [users, setUsers] = React.useState<User[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const search = params.get('search') || '';
    const wineries = params.get('wineries') || '';

    const getUsers = (params?: SearchParams) => {
        const { search, wineries } = params || {};
        // initialize empty params object
        const paramsObj: {
            search?: string;
            wineries?: string;
        } = {};

        // conditionally add search params to object
        if (search) {
            paramsObj.search = search;
        }
        if (wineries) {
            paramsObj.wineries = wineries;
        }

        // construct url with search params
        const searchParams = new URLSearchParams(paramsObj);
        const url = new URL('/api/users', window.location.origin);
        url.search = searchParams.toString();
        const pathWithParams = url.pathname + url.search;

        // fetch users
        callApi({ url: pathWithParams }).then((data: User[]) => {
            setUsers(data || []);
            setPage(0);
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedGetUsers = React.useCallback(
        debounce(({ search, wineries }: SearchParams) => {
            getUsers({ search, wineries });
        }, 500),
        []
    );

    React.useEffect(() => {
        debouncedGetUsers({
            search: search || '',
            wineries: wineries || ''
        });
    }, [search, wineries, debouncedGetUsers]);

    const setSearch = (query: string) => {
        const queryParams = new URLSearchParams(location.search);
        if (query.length === 0) {
            queryParams.delete('search');
        } else {
            queryParams.set('search', query);
        }

        navigate(`?${queryParams.toString()}`);
    };

    const handleClickOpen = () => {
        navigate(`/admin/users/new`);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to first page when changing rows per page
    };

    const slicedUsers = users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    return (
        <Box
            component='main'
            sx={{
                backgroundColor: theme =>
                    theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto'
            }}
        >
            <FormDialog getUsers={getUsers} />
            <MetaTitle title='Users' />

            <Toolbar />
            <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                            <Grid container spacing={3} direction='row' pb={2}>
                                <Grid item md={2} xs={12}>
                                    <Typography component='h2' variant='h6' color='primary' gutterBottom>
                                        Users
                                    </Typography>
                                </Grid>

                                <Grid item md={4} xs={12} direction='row' justifyContent='center'>
                                    <TextField
                                        size='small'
                                        fullWidth
                                        variant='outlined'
                                        value={search}
                                        onChange={handleSearchChange}
                                        label='Search for a user'
                                    />
                                </Grid>
                                <Grid item md={3} xs={12} textAlign='right'>
                                    <WinerySelect />
                                </Grid>
                                <Grid item md={3} xs={12} textAlign='right'>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={handleClickOpen}
                                        startIcon={<PersonAdd />}
                                    >
                                        Add User
                                    </Button>
                                </Grid>
                            </Grid>
                            {loading ? (
                                <CircularProgress color='inherit' />
                            ) : (
                                slicedUsers.map(user => (
                                    <React.Fragment key={user.SamAccountName}>
                                        <ListItem
                                            button
                                            component={Link}
                                            to={`/admin/users/edit/${user.SamAccountName}`}
                                        >
                                            <ListItemAvatar>
                                                <Avatar>
                                                    {user.FirstName?.charAt(0)}
                                                    {user.LastName?.charAt(0)}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={`${user.FirstName} ${user.LastName}`}
                                                secondary={
                                                    <>
                                                        {user.UserRole} {user.Email}
                                                    </>
                                                }
                                            />
                                        </ListItem>
                                    </React.Fragment>
                                ))
                            )}
                            <TablePagination
                                component='div'
                                count={users.length}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Users;
