import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MetaTitle from '../../components/MetaTitle';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../hooks/api';
import { Subscription } from '.';
import { Autocomplete, Button, TextField, Tooltip, Typography } from '@mui/material';
import { useStatus } from '../../contexts/status';
import { PersonOutline } from '@mui/icons-material';

export interface Emails {
    TO?: string[];
    CC?: string[];
    BCC?: string[];
}

export type EmailEnum = 'TO' | 'CC' | 'BCC';

const SubscriptionEdit = () => {
    const { id } = useParams();
    const [subscription, setSubscription] = React.useState<Subscription | null>();
    const { callApi } = useApi();
    const [emails, setEmails] = React.useState<Emails>({});
    const [dirty, setDirty] = React.useState(false);
    const navigate = useNavigate();
    const { setStatus } = useStatus();

    const wineryName = subscription?.Winery_Name || subscription?.GroupName || '';

    const hasValidWineryName = !!subscription?.Winery_Name;

    const updateSubscription = async (emails: Emails) => {
        return callApi({
            url: `/api/subscriptions/${id}`,
            method: 'PATCH',
            exposeError: true,
            body: {
                emails
            }
        });
    };

    const getSubscription = React.useCallback(async () => {
        const subscription: Subscription | null = await callApi({ url: `/api/subscriptions/${id}`, exposeError: true });
        if (!subscription) {
            return;
        }
        setSubscription(subscription);
        if (subscription.Emails) setEmails(subscription.Emails);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    React.useEffect(() => {
        if (subscription?.Emails) setEmails(subscription.Emails);
    }, [subscription]);

    React.useEffect(() => {
        getSubscription();
    }, [getSubscription]);

    const handleSave = async () => {
        // if there's nothing to save, don't try to save
        if (!dirty) {
            return;
        }

        if (!subscription?.Emails) return;

        const updatedSubscription = await updateSubscription(subscription?.Emails);

        if (!updatedSubscription) return;

        setSubscription(updatedSubscription);
        setStatus({ type: 'success', message: 'Subscription updated.' });
        setDirty(false);
        navigate('/admin/subscriptions');
    };

    const handleAddEmail = async (newInputValue: string, emailType: EmailEnum) => {
        // validate email
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(newInputValue)) {
            setStatus({ type: 'error', message: 'Invalid email address' });
            return;
        }

        if (!subscription) return;

        const updatedEmailObj = {
            ...subscription?.Emails,
            [emailType]: [...(emails[emailType] || []), newInputValue]
        };
        setDirty(true);
        setSubscription({ ...subscription, Emails: updatedEmailObj });
    };

    const handleInputChange = async (target: HTMLInputElement, emailType: EmailEnum) => {
        if (!!(target as HTMLInputElement).value.trim()) {
            const newInputValue = (target as HTMLInputElement).value;
            await handleAddEmail(newInputValue, emailType);
        } else {
            // if there is nothing in the input, handle save
            await handleSave();
        }
    };

    return (
        <Box
            component='main'
            sx={{
                backgroundColor: theme =>
                    theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto'
            }}
        >
            <MetaTitle title='Subscription' />

            <Toolbar />
            <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container spacing={3} pb={2} direction='row' justifyContent='space-between'>
                                <Grid item>
                                    <Typography component='h2' variant='h6' color='primary' gutterBottom>
                                        {subscription?.Description || '(no description)'}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={3} pb={2} direction='row' justifyContent='flex-end'>
                                        <Grid item>
                                            <Typography
                                                component='h2'
                                                variant='h6'
                                                color='primary'
                                                gutterBottom
                                                textAlign='right'
                                            >
                                                {wineryName}
                                            </Typography>
                                        </Grid>
                                        {hasValidWineryName && (
                                            <Grid item>
                                                <Typography
                                                    component='h2'
                                                    variant='h6'
                                                    color='primary'
                                                    gutterBottom
                                                    textAlign='right'
                                                >
                                                    <Tooltip title='View Users for Winery'>
                                                        <Button
                                                            startIcon={<PersonOutline />}
                                                            onClick={() =>
                                                                navigate(`/admin/users?wineries=${wineryName}`)
                                                            }
                                                        >
                                                            Users
                                                        </Button>
                                                    </Tooltip>
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography mb={2}>
                                        Subject: {subscription?.ExtensionSettingsParsed?.Subject}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} pb={2} direction='column'>
                                <Grid item xs={12}>
                                    <Typography mb={2}>Recipients</Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} pb={2} direction='column'>
                                {Object.entries(emails).map(([emailType, emails], parentIdx) => (
                                    <Grid key={parentIdx} item xs={12}>
                                        <Autocomplete
                                            disableClearable
                                            forcePopupIcon={false}
                                            multiple
                                            id='tags-standard'
                                            options={[]}
                                            open={false}
                                            value={emails || []}
                                            getOptionLabel={option => option}
                                            renderInput={params => (
                                                <TextField {...params} variant='standard' label={emailType} />
                                            )}
                                            onChange={async (event, newEmails): Promise<void> => {
                                                if (!subscription) return;

                                                const updatedEmailObj = {
                                                    ...subscription?.Emails,
                                                    [emailType]: newEmails
                                                };

                                                setSubscription({ ...subscription, Emails: updatedEmailObj });
                                                setDirty(true);
                                            }}
                                            onInputChange={(event, newInputValue) => {
                                                // trim off leading spaces
                                                newInputValue = newInputValue.replace(/^\s+/, '');

                                                // split on spaces
                                                const separator = /[ ,]+/;
                                                const newEmails = separator.test(newInputValue)
                                                    ? newInputValue.split(separator)
                                                    : [];
                                                const newEmailsNoEmptyStrings = newEmails.filter(email => !!email);
                                                if (newEmailsNoEmptyStrings.length) {
                                                    // trim off any leading or trailing spaces
                                                    newEmailsNoEmptyStrings[0] = newEmailsNoEmptyStrings[0].trim();
                                                    const newEmail = newEmailsNoEmptyStrings[0];
                                                    handleAddEmail(newEmail, emailType as EmailEnum);
                                                }
                                            }}
                                            onKeyDown={async event => {
                                                if (event.key === 'Enter' || event.key === 'Tab') {
                                                    // if there is something in the input, add it to the list
                                                    handleInputChange(
                                                        event.target as HTMLInputElement,
                                                        emailType as EmailEnum
                                                    );
                                                }
                                            }}
                                            onBlur={async event => {
                                                // if there is something in the input, add it to the list
                                                handleInputChange(
                                                    event.target as HTMLInputElement,
                                                    emailType as EmailEnum
                                                );
                                            }}
                                        />
                                    </Grid>
                                ))}
                            </Grid>

                            <Grid
                                container
                                spacing={3}
                                gap={2}
                                mt={2}
                                direction='row'
                                justifyContent='end'
                                alignItems='center'
                            >
                                <Button onClick={() => navigate('/admin/subscriptions')}>Cancel</Button>
                                <Button onClick={handleSave} disabled={!dirty} variant='contained'>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    );
};

export default SubscriptionEdit;
