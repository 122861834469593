import React, { useEffect, useState } from 'react';
import { Stack, Typography, TextField, Button } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme';
import { WINEPULSE_TITLE } from '../constants/api';
import { LockPerson } from '@mui/icons-material';
import useApi from '../hooks/api';
import { useAlertModal } from '../contexts/alertModal';
import { useLocation } from 'react-router-dom';

const UpdatePassword = () => {
    const { callApi } = useApi();
    const { alertModal } = useAlertModal();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const usernameParam = urlParams.get('u');
    const isAdminRequest = urlParams.get('a') === 'true';

    const [username, setUsername] = useState('');

    useEffect(() => {
        document.title = `Password Reset Request ${WINEPULSE_TITLE}`;
    }, []);

    useEffect(() => {
        // check if query string has `u` in it. If so, set the username state to that value
        if (usernameParam) {
            setUsername(usernameParam);
        }
    }, [usernameParam]);

    const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setUsername(value);
    };

    const handleConfirm = async (e: React.FormEvent) => {
        e.preventDefault();
        const data = await callApi({
            url: `/api/auth/request-reset-password`,
            body: { username, isAdminRequest },
            method: 'POST',
            exposeError: true
        });
        if (data && data.message === 'Password reset email sent') {
            alertModal({
                title: 'Password Reset Email Sent',
                content: (
                    <Typography>
                        If a user account exists for this username, a password reset email has been sent. Please follow
                        the instructions in the email to reset your password.
                    </Typography>
                )
            });
        }
    };

    return (
        <div
            id='UpdatePassword'
            style={{
                width: '100vw',
                height: '100dvh',
                backgroundColor: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <form autoComplete='on' onSubmit={handleConfirm}>
                <Stack
                    spacing={2}
                    direction='column'
                    textAlign='center'
                    alignItems='center'
                    sx={{ width: '100%', maxWidth: '350px' }}
                >
                    <Typography variant='h3' sx={{ mb: '10px', pointerEvents: 'none', fontSize: '3rem' }}>
                        <LockPerson />
                    </Typography>
                    <Typography
                        variant='h4'
                        sx={{ fontFamily: 'Noto Sans, sans-serif', letterSpacing: '-1px', width: '350px', mb: '20px' }}
                    >
                        <b>Forgot Password</b>
                    </Typography>
                    <Typography variant='body2'>
                        Please enter your username to begin the password reset process.
                    </Typography>
                    <ThemeProvider theme={theme}>
                        <TextField
                            id='username'
                            autoComplete='on'
                            value={username}
                            onChange={handleUsernameChange}
                            type='text'
                            label='Username'
                            variant='outlined'
                            color='primary'
                            sx={{ width: 'calc(100% - 20px)' }}
                        />
                        <Button
                            variant='contained'
                            type='submit'
                            color='primary'
                            sx={{
                                width: 'calc(100% - 20px)',
                                height: '50px',
                                top: '10px'
                            }}
                        >
                            Continue
                        </Button>
                        {/* <Typography variant='body2' sx={{ fontFamily: 'Noto Sans, sans-serif', position: 'relative', top: '10px' }}>
                            Don't have an account?{' '}
                            <Link color='primary' href='/auth/signup' sx={{ textDecoration: 'none' }}>
                                Sign up
                            </Link>
                        </Typography> */}
                    </ThemeProvider>
                </Stack>
            </form>
        </div>
    );
};

export default UpdatePassword;
